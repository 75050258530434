<template>
  <main id="contents" class="drops_page detail_page">
    <h1 class="page_ttl is_blind">Drops Detail</h1>
    <section class="overview_sec">
      <div
        class="bg_el"
        :style="`background-image: url(${artworkBackground})`"
      ></div>
      <div class="set_inner">
        <header class="sec_head">
          <div class="thumb_area">
            <img
              v-if="artworkInfo.mimeType === 'image/jpeg'"
              :srcset="`${source_small} 590w, ${source_medium} 1920w, ${source} 3840w`"
              :src="source_small"
              width="890"
              height="500"
              :alt="artworkAlt ? artworkAlt : ''"
            />
            <video
              v-else
              loop
              autoplay
              playsinline
              muted
              style="width: auto"
              :src="source"
            >
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <button
            class="btn type_ic btn_like"
            type="button"
            :class="{
              is_active: likeStatus,
            }"
            @click="likeThis(artworkId)"
          >
            <!-- icon-color="#fff"  FF521D #ff521d-->
            <IcLike
              v-if="!likeStatus"
              :status="!likeStatus"
              icon-color="#fff"
              ref="icLike"
            />
            <IcLike v-else icon-color="#ff521d" ref="icLike" />
          </button>
        </header>
        <div class="sec_body">
          <div class="ttl_wrap">
            <div class="ttl_area">
              <h3 class="ttl">{{ artworkInfo.title }}</h3>
            </div>
            <div class="profile_info is_art" @click="goToProfile('2')">
              <span
                v-if="artistAvatarInfo && artistAvatarInfo.length"
                class="profile"
                :style="`background-image: url(${artistAvatarInfo[0].resUrl})`"
              ></span>
              <IcProfile v-else class="profile" />
              <IcRing class="ic_ring" />
              <span class="name">{{ artworkInfo.artistName }}</span>
            </div>
            <p class="edit">Edition of {{ artworkInfo.totalEdition }}</p>

            <!-- s: DEV 221207 추가  -->
            <template
              v-if="
                artworkInfo.bcNwTp == '10' &&
                hdrWallet.walletYn === 'N' &&
                isMob &&
                (openBlade || openWallypto)
              "
            >
              <div class="input_area select drop_ui">
                <div class="input_box drop_box">
                  <button
                    class="drop_btn has_arw"
                    :class="{ is_active: walletSelect }"
                    role="combobox"
                    aria-haspopup="listbox"
                    aria-controls="dropWalletOpts"
                    :aria-activedescendant="`wallet_op_${walletFocusOpt}`"
                    :aria-expanded="walletSelect ? 'true' : 'false'"
                    @click="walletSelect = !walletSelect"
                  >
                    {{ walletOpt.label }}
                    <b v-if="walletOpt.value === 'WLT001'" class="badge"
                      >Popular</b
                    >
                    <ArwSelect aria-hidden="true" />
                  </button>
                  <transition name="fade">
                    <ul v-if="walletSelect" class="drop_op_list" role="listbox">
                      <li
                        v-for="(opt, i) in walletOptData"
                        :key="'wallet_op_' + i"
                        :id="'wallet_op_' + i"
                        role="option"
                        aria-selected="false"
                        :class="[
                          { is_focus: walletFocusOpt === i },
                          { is_active: walletOpt.value === opt.value },
                        ]"
                        @click="walletOtp(opt, i)"
                      >
                        <span>{{ opt.label }}</span>
                        <b v-if="opt.value === 'WLT001'" class="badge"
                          >Popular</b
                        >
                      </li>
                    </ul>
                  </transition>
                </div>
              </div>
            </template>
            <!-- e: DEV 221207 추가  -->
          </div>
          <div class="buy_wrap float_el">
            <div v-if="!false">
              <CountDown
                v-if="artworkInfo.dropsDivision == 'live'"
                :date-time="artworkInfo.dropsEndDate"
              />
            </div>
            <!-- PUB : 블록체인 아이콘 추가 및 금액표기 수정 -->
            <div class="buy_group">
              <div class="label_list_group">
                <dl class="label_list">
                  <dt>Blockchains</dt>
                  <dd class="">
                    <p class="blockchains">
                      <!-- WIP : bcNwTp 분기 아이콘-->
                      <template v-if="artworkInfo.bcNwTp == '10'">
                        <IcHederaS />
                        <b>Hedera</b>
                      </template>
                      <template v-else>
                        <IcEthS />
                        <b>Ethereum</b>
                      </template>
                    </p>
                  </dd>
                </dl>
                <dl class="label_list">
                  <dt>Price</dt>
                  <dd class="">
                    <p v-if="!false" class="price">
                      <!-- WIP : bcNwTp 분기 금액-->
                      <b v-if="artworkInfo.bcNwTp == '10'"
                        >{{ artworkInfo.price | setNum }} USDC</b
                      >
                      <b v-else
                        >{{ artworkInfo.price }}<span class="unit">Ξ</span
                        ><span class="dollars"
                          >({{
                            artworkInfo.priceToEthUsd | crtpyAbbreviate
                          }})</span
                        ></b
                      >
                    </p>
                    <p v-else class="price">
                      <b>-</b>
                    </p>
                  </dd>
                </dl>
                <dl class="label_list">
                  <dt>Estimated <br class="dv_mob" />Tax</dt>
                  <dd class="">
                    <p class="price">
                      <!-- WIP : bcNwTp 분기 금액 로그인 안했을때는 금액노출 제외-->
                      <template v-if="accessToken && zipCodeCheck">
                        <p class="price">
                          <b v-if="artworkInfo.bcNwTp == '10'"
                            >{{ taxOnly | setNum }} USDC</b
                          >
                          <b v-else
                            >{{ taxOnly }}<span class="unit">Ξ</span
                            ><span class="dollars"
                              >({{
                                artworkInfo.taxToEthUsd | crtpyAbbreviate
                              }})</span
                            ></b
                          >
                        </p>
                      </template>
                      <template v-else-if="accessToken && !zipCodeCheck">
                        <b
                          ><a
                            href="javascript:;"
                            class="c_point"
                            @click="directUpdateInfo"
                            >Update your info</a
                          >
                          to check the sales tax estimate.</b
                        >
                      </template>
                      <template v-else-if="!accessToken">
                        <b
                          ><a
                            href="javascript:;"
                            class="c_point"
                            @click="loginPopup()"
                            >Sign in</a
                          >
                          to check the sales tax estimate.</b
                        >
                      </template>
                    </p>
                  </dd>
                </dl>
              </div>
              <button
                v-if="false"
                class="btn w_f strong h_l"
                ref="modalBtn"
                @click="openContactModal()"
              >
                Contact Artist to Buy
              </button>
              <button
                v-else
                class="btn w_f strong h_l"
                :class="{ isDeactive: isLoading }"
                :disabled="
                  getDropStatus == 'Sold Out' ||
                  getDropStatus == 'Ended' ||
                  getDropStatus == 'Upcoming' ||
                  !openService
                "
                @click="purchaseEvt"
              >
                {{ getDropStatus }}
              </button>
            </div>
          </div>
          <div class="desc_wrap">
            <div class="desc_group more_ui" :class="{ is_more: showMoreDesc }">
              <h4 class="ttl type_underL">Description</h4>
              <p class="desc">{{ artworkInfo.artworkDesc }}</p>
              <button
                class="btn type_txt has_arw dv_mob toggle_desc"
                @click="showMoreDesc = !showMoreDesc"
              >
                {{ showMoreDesc ? 'Close' : 'More' }}
              </button>
            </div>
            <div class="tag_group">
              <h4 class="ttl type_underL">Tag</h4>
              <ul class="tag_list clearFix">
                <li
                  v-for="(tag, i) in artworkInfo.keywords"
                  :key="'tag_' + i"
                  class="tag_node"
                >
                  <button class="tag_item" @click="goTagSearch(tag)">
                    <span>{{ tag }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="set_inner">
      <section class="detail_sec detail_info_sec">
        <header ref="tabs" class="sec_head">
          <div class="tab_wrap">
            <!-- Dev: tab이 하나일 경우 type_stack 클래스 바인드 -->
            <div class="tab_list" role="tablist">
              <div
                v-for="tab in infoTabData"
                :key="tab.id"
                class="tab_node"
                role="presentation"
              >
                <button
                  :id="'tab_' + tab.id"
                  class="tab_item"
                  role="tab"
                  :class="{ is_active: currentTab === tab.id }"
                  @click="setTab(tab.id)"
                  :aria-controls="tab.id + '_panel'"
                  :aria-selected="tab.id === currentTab ? true : false"
                >
                  <IcTime v-if="tab.icon === 'time'" stroke-color="#fff" />
                  <IcItems v-if="tab.icon === 'items'" stroke-color="#fff" />
                  <IcEye v-if="tab.icon === 'eye'" stroke-color="#fff" />
                  <IcVideo v-if="tab.icon === 'video'" stroke-color="#fff" />
                  <span>{{ tab.name }}</span>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div
          :id="currentTab + '_panel'"
          class="sec_body tab_panel"
          role="tabpanel"
          :aria-labelledby="'tab_' + currentTab"
        >
          <template v-if="currentTab === 'status'">
            <div class="detail_info_wrap">
              <div class="detail_info_inner">
                <!-- WIP : refresh 제거 -->
                <header class="detail_info_head">
                  <!-- <p class="info_txt refresh">
                    <IcRefreshS stroke-color="var(--txt-gray-2)" />
                    <span>Refresh in 2 minutes</span>
                  </p> -->
                  <ul class="status_list_head">
                    <li class="history_info">
                      {{ /* artworkInfo.bcNwTp == '20' ? 'Market' : 'Buyer' */ }}
                      Buyer
                    </li>
                    <li class="date_info">Date and Time</li>
                    <li class="market_info">
                      <span v-if="artworkInfo.bcNwTp == '20'">Market</span>
                    </li>
                    <li class="price_info">Price</li>
                  </ul>
                  <!-- PUB : 리프레시 인포 삭제/ 리스트 헤더 추가 -->
                </header>
                <div class="detail_info_body">
                  <ul class="status_list">
                    <li
                      v-for="(status, i) in statusInfos"
                      :key="'status_' + i"
                      class="status_node"
                    >
                      <!-- PUB : 레이아웃 변경/ 데이트인포 위치 변경/ 마켓인포 추가 S -->
                      <div class="status_item">
                        <div class="user_info">
                          <div
                            class="profile_info"
                            @click="
                              goToProfile(
                                status.artistYn === 'N' ? '1' : '2',
                                status.buyerId,
                              )
                            "
                            :class="{ is_art: status.artistYn === 'Y' }"
                          >
                            <span
                              v-if="
                                status.buyerAvatarInfo &&
                                status.buyerAvatarInfo.length > 0
                              "
                              class="profile"
                              :style="`background-image: url(${status.buyerAvatarInfo[0].resUrl})`"
                            ></span>
                            <IcProfile
                              v-else
                              :width="50"
                              :height="50"
                              stroke-width=".7"
                              class="profile"
                            />
                            <IcRing
                              v-if="status.artistYn === 'Y'"
                              :width="50"
                              :height="50"
                              :small-ring="14.8"
                              class="ic_ring"
                            />
                          </div>
                          <div class="txt_info">
                            <span class="name">{{ status.buyerName }}</span>
                          </div>
                        </div>
                        <div class="date_info">
                          <p class="date">
                            {{ status.buyDate | GmtToTz | UsaFormat }}
                          </p>
                        </div>
                        <div class="market_info">
                          <span v-if="artworkInfo.bcNwTp == '20'"
                            >LG Art Lab</span
                          >
                        </div>
                        <div class="price_info">
                          <p class="price">
                            <b v-if="status.bcNwTp == '10'"
                              >{{ status.buyPrice | setNum }} USDC</b
                            >
                            <b v-else
                              >{{ status.buyPrice }}<span class="unit">Ξ</span
                              ><span class="dollars">
                                ({{ status.priceToEthUsd | crtpyAbbreviate }})
                              </span></b
                            >
                          </p>
                        </div>
                      </div>
                      <!-- PUB : E -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-if="currentTab === 'editions'">
            <div class="list_wrap detail_info_wrap">
              <header class="list_head detail_info_head" ref="ulRef">
                <p class="count">
                  Edition of&nbsp;
                  <span class="c_point">{{ artworkInfo.totalEdition }}</span>
                </p>
              </header>
              <div class="list_body detail_info_body">
                <ul class="grid grid_10 thumb_list ed_list">
                  <li
                    v-for="(item, i) in editionItems"
                    :key="'edit_' + i"
                    class="col col_3 thumb_node"
                  >
                    <div class="thumb_item" @click="showFullScreen(i, item)">
                      <div class="thumb_area">
                        <button>
                          <img
                            v-if="item.mimeType === 'video/mp4'"
                            :srcset="`${item.videoThumbInfos[0].resUrl} 2x,
                        ${item.videoThumbInfos[1].resUrl}`"
                            :src="`${item.videoThumbInfos[1].resUrl}`"
                            :alt="
                              artworkInfo.title + 'Editions ' + item.editionId
                            "
                          />
                          <img
                            v-else
                            :srcset="`${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`"
                            :src="`${item.imageInfos[1].resUrl}`"
                            :alt="
                              artworkInfo.title + 'Editions ' + item.editionId
                            "
                          />
                          <span class="is_blind">{{ artworkInfo.title }}</span>
                        </button>
                      </div>
                      <!-- <div
                        v-if="item.mimeType === 'video/mp4'"
                        class="ic_area type_video"
                      > -->
                      <!-- icon-color="#fff"  strokeColor  FF521D-->
                      <!-- <IcVideo /> 
                      </div> -->
                      <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
                      <div class="ic_area type_blockchains">
                        <!-- <IcHedera /> -->
                        <IcEth />
                      </div>
                      <p class="edit">
                        <span>
                          {{ `${i + 1} of ${artworkInfo.totalEdition}` }}
                          Edition</span
                        >
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <InfinityScroll @scroll="loadMore" />
            </div>
          </template>
          <template v-else-if="currentTab === 'docent'">
            <!-- :item-data="dropsItemDetail.docent" -->
            <!-- :item-data="artworkInfo.docentInfo.items" -->
            <DocentThumbSlide
              :item-data="docentThumbItems"
              @setCurrent="setDocentCurrent"
            />
            <div class="viewer_wrap type_img">
              <div class="img_area">
                <img
                  v-if="currentDocent.item.thumbInfos[0].resUrl !== undefined"
                  :src="currentDocent.item.thumbInfos[0].resUrl"
                  :width="1220"
                  alt="currentDocent_Image"
                />
                <img
                  v-else
                  :src="docentInfo.imageInfos[0].resUrl"
                  :width="1220"
                  alt="currentDocent_Image"
                />
              </div>
              <div class="desc_area">
                <p>{{ currentDocent.item.content }}</p>
              </div>
            </div>
          </template>
          <template v-if="currentTab === 'film'">
            <div class="viewer_wrap type_video">
              <div class="video_area" style="text-align: center">
                <video
                  ref="video"
                  @ended="isPlay = false"
                  id="makingFilm"
                  :aria-label="'makingFilm for ' + artworkInfo.title"
                >
                  <source :src="makingInfos[0].resUrl" type="video/mp4" />
                </video>
              </div>
              <div class="controls_area type_start">
                <button class="btn type_ic player" @click="makingfilmPlayPause">
                  <IcPlayL v-if="!isPlay" class="ic_play" />
                  <IcPauseL v-else class="ic_pause" />
                </button>
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
    <QrModal
      v-if="showQrModal"
      modal-id="qrModal"
      :modal-data="purchaseData"
      :showQrModal="showQrModal"
      :dropId="dropId"
      :artworkId="artworkId"
      @close="close"
    />

    <ContactArtistModal ref="ContactArtistModal" @close="close" />
    <SetPriceModal ref="SetPriceModal" modal-size="m" />
    <ConnectWalletModal :showWallet="showWallet" @close="close" />
    <PurchaseProgressModal :showEthProgress="showEthProgress" @close="close" />
    <InsufficientBalance
      :showUsdc="showUsdc"
      :associatedSign="associatedSign"
      @close="close"
      :modal-data="usdcModalInfo"
    />
    <UpdateInfoModal
      :price="artworkInfo.price"
      :selectId="selectArtworkId"
      ref="UpdateInfoModal"
      @zipcodeSave="zipcodeSave"
    />
    <DetailViewer
      v-if="isShowDetailView"
      :collections="singleEdition"
      :currentIndex="editionIdx"
      :dropInfo="artworkInfo"
      :isFullScreen="true"
      @hide="hide"
    />
  </main>
</template>

<script>
import IcLike from '@/components/ic/IcLike';
import IcTime from '@/components/ic/IcTime';
import IcItems from '@/components/ic/IcItems';
import IcEye from '@/components/ic/IcEyes';
import IcVideo from '@/components/ic/IcVideo';
//import IcRefreshS from '@/components/ic/IcRefreshS';
import IcProfile from '@/components/ic/IcProfile';
import IcPlayL from '@/components/ic/IcPlayL';
import IcPauseL from '@/components/ic/IcPauseL';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
// import IcHedera from '@/components/ic/IcHedera';
import IcEthS from '@/components/ic/IcEthS';
import IcHederaS from '@/components/ic/IcHederaS';
import DocentThumbSlide from '@/components/drops/DocentThumbSlide';
import QrModal from '@/components/common/QrModal';
import CountDown from '@/components/drops/CountDown';
import SetPriceModal from '@/components/common/SetPriceModal';
import ConnectWalletModal from '@/components/marketplace/ConnectWalletModal';
import InsufficientBalance from '@/components/marketplace/InsufficientBalance';
import UpdateInfoModal from '@/components/marketplace/UpdateInfoModal';
import PurchaseProgressModal from '@/components/marketplace/PurchaseProgressModal';
import DetailViewer from '@/views/DetailViewer.vue';
import InfinityScroll from '@/components/common/InfinityScroll';
import ArwSelect from '@/components/ic/arwSelect';
import ContactArtistModal from '@/components/common/ContactArtistModal';

import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'DropsView',
  components: {
    ConnectWalletModal,
    InsufficientBalance,
    UpdateInfoModal,
    SetPriceModal,
    QrModal,
    CountDown,
    DocentThumbSlide,
    IcRing,
    IcPlayL,
    IcPauseL,
    IcProfile,
    //IcRefreshS,
    IcVideo,
    IcEye,
    IcItems,
    IcTime,
    IcLike,
    IcEth,
    // IcHedera,
    IcEthS,
    IcHederaS,
    DetailViewer,
    InfinityScroll,
    ArwSelect,
    PurchaseProgressModal,
    ContactArtistModal,
  },
  data() {
    return {
      overtimer: 180000,
      likeStatus: false,
      editionIdx: 0,
      source: '',
      source_medium: '',
      source_small: '',
      polling: null,
      showQrModal: false,
      showWallet: false,
      showUsdc: false,
      dropId: '',
      artworkId: '',
      usdcBalance: 0,
      docentThumbItems: [],
      isShowDetailView: false,
      isLoading: false,
      tabQueue: [
        {
          id: 'editions',
          name: 'Editions',
          icon: 'items',
          class: 'type_edit',
        },
        {
          id: 'docent',
          name: 'Docent Art Show',
          icon: 'eye',
          class: 'type_docent',
        },
        {
          id: 'film',
          name: 'Making Film',
          icon: 'video',
          class: 'type_film',
        },
      ],
      statusInfos: [],
      editionItems: [],
      artworkInfo: {},
      makingInfos: {},
      artworkBackground: '',
      bladeAppImage: '',
      artworkAlt: '',
      artworkThumb: '',
      artworkThumb2x: '',
      artistAvatarInfo: [],
      associatedYn: 'N',
      docentInfo: {},
      imageInfos: [],
      infoTabData: [
        {
          id: 'status',
          name: 'Current status',
          icon: 'time',
          class: 'type_status',
        },
      ],
      currentTab: 'status',
      startVideo: false,
      currentDocent: {
        idx: 0,
        item: null,
      },
      isPlay: false,
      tabPos: 0,
      isMob: false,
      hasWallet: false,
      MYADDR: false,
      showMoreDesc: false,
      showEthProgress: false,
      purchaseData: {
        title: 'Purchase',
        desc: 'To purchase artworks, turn on the camera on your mobile phone and scan the QR.',
        qr: {
          path: 'img_qr.png',
        },
      },
      query: {},
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      getDropStatus: '',
      page: 1,
      viewCount: 20,
      totalCount: 0,
      isPlayer: true,
      playerTimeoutId: null,
      accountId: '',
      accountInfo: {
        title: 'Create USDC Coin Account',
        desc: "We have noticed you don't have a USDC Coin account. Please sign in to create USDC Coin account in Wallypto to purchase.",
        button: 'Go to Wallypto',
      },
      // NOTE: 220826 modal message change requested by pm
      InsufficientModalInfo: {
        title: 'Add USDC Coin',
        desc: "We have noticed you don't have enough USDC Coin in your Wallypto.\n Please buy USDC Coin from exchanges and add the USDC Coin token to your Wallypto app to check final balance.",
        button: 'Purchase USDC Coin',
      },
      associatedSign: '',
      usdcModalInfo: undefined,
      associatedError: false,
      walletTokenExpired: false,
      zipCodeCheck: false,
      walletSelect: false,
      walletFocusOpt: 0,
      walletOpt: {},
      walletOptData: [],
      selectArtworkId: '',
      resultPrice: 0,
      singleEdition: [],
      showContactArtistForm: false,
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      stateYn: 'userStore/stateYn',
      wlltType: 'userStore/wlltType',
      email: 'userStore/email',
      ethWallet: 'userStore/ethWallet',
      hdrWallet: 'userStore/hdrWallet',
      provider: 'web3Store/provider',
      topic: 'web3Store/topic',
      chains: 'web3Store/chains',
      accounts: 'web3Store/accounts',
      getWeb3ModalStatus: 'web3Store/getWeb3ModalStatus',
    }),
    shouldShowCountdown() {
      return (
        process.env.VUE_APP_PROFILE === 'PRD' &&
        this.$route.query.dropId === 'D0000000037'
      );
      //return true;
    },
    taxOnly() {
      return this.artworkInfo.tax;
    },
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
    openBlade() {
      return JSON.parse(process.env.VUE_APP_OPEN_BLADE) ? true : false;
    },
    openWallypto() {
      return JSON.parse(process.env.VUE_APP_OPEN_WALLYPTO) ? true : false;
    },
  },
  watch: {
    accessToken(newVal, oldVal) {
      if (newVal == oldVal) {
        return null;
      }
      this.setSelectWallet();
      this.zipCodeValidation();
      this.getDropArtworkDetail();
    },
  },
  created() {
    this.$root.$emit('setPage', {
      page: 'detail_page',
    });
  },
  async mounted() {
    this.isMob = this.$isMobile();

    if (this.$route.query.dropId) {
      this.dropId = this.$route.query.dropId;
      this.artworkId = this.$route.query.artworkId;
      this.query = {
        page: this.page,
        dropId: this.dropId,
        artworkId: this.artworkId,
      };
    } else {
      this.$router.push('/drops');
      return false;
    }

    //changeSellingModal Myself true 일때 로그인 안내팝업
    if (!this.accessToken && this.$route.query.myself) {
      this.$root.$emit('openAlert', this.loginAlert);
    }
    await this.setSelectWallet();
    await this.zipCodeValidation();
    await this.getDropArtworkDetail();
    await this.getStatusInfos();

    //edition info
    await this.api.getDropEditionItems(this.query).then(res => {
      if (res.code === 0) {
        this.editionItems = res.editionItems;
        this.totalCount = this.artworkInfo.totalEdition;
      }
    });

    if (this.editionItems) {
      await this.tabManager();
    }
    window.addEventListener('scroll', this.setFixedTab);
    //this.getRefresh(); // refresh 기능 제외
  },
  methods: {
    async setSelectWallet() {
      let blade = {
        label: 'Blade Wallet',
        value: 'WLT001',
      };

      let wallypto = {
        label: 'Wallypto',
        value: 'WLT002',
      };

      if (this.openBlade) {
        this.walletOptData.push(blade);
      }
      if (this.openWallypto) {
        this.walletOptData.push(wallypto);
      }

      if (this.hdrWallet.walletYn === 'Y') {
        this.walletOpt = this.wlltType === 'WLT002' ? wallypto : blade;
      } else {
        this.walletOpt = this.openBlade ? blade : wallypto;
      }
    },
    async zipCodeValidation() {
      if (!(await this.checkZipcode()) && this.stateYn === 'N') {
        this.zipCodeCheck = false;
      } else {
        this.zipCodeCheck = true;
      }
    },
    async checkZipcode() {
      if (!this.accessToken) return false;
      return await this.api.getZipCode({}).then(res => {
        if (res.code === 0) {
          return true;
        } else {
          return false;
        }
      });
    },
    //에디션 클릭 시 미리보기 관련된 함수 : showFullScreen / hide
    showFullScreen(idx, item) {
      this.editionItems.forEach(x => {
        x.title = this.artworkInfo.title;
        x.description = this.artworkInfo.artworkDesc;
      });
      //23-01-20 viewer 단일로 변경
      //this.editionIdx = idx;

      // NOTE: 23-02-13  expose FHD video(1920 * 1080) at detailViewer - requested by hyLee (PM)
      // TODO : resource List sort method commonization ( like mixins )
      let standardOfPriority = 51;
      if (item?.videoInfos && item.videoInfos.length > 0) {
        item.videoInfos.sort((a, b) => {
          if (
            Math.abs(a.rsrcTp - standardOfPriority) >
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return 1;
          } else if (
            Math.abs(a.rsrcTp - standardOfPriority) <
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return -1;
          } else {
            return a.rsrcTp < b.rsrcTp ? 1 : -1;
          }
        });
      }
      this.singleEdition = [item];
      this.isShowDetailView = true;
    },
    hide(data) {
      this.singleEdition = [];
      if (this.backgroundPlay) {
        this.currentItem =
          this.item.myCollectionInfos[0].collectionInfos[data.index];
        if (this.currentItem.mimeType === 'video/mp4') {
          if (this.$refs.videoRef) {
            this.$refs.videoRef.play();
          } else {
            const video = document.createElement('video');
            video.src = this.currentItem.videoInfos[0].resUrl;
            video.currentTime = data.currentTime;
            video.play();
          }
        }
      }
      this.isShowDetailView = false;
    },
    close(type) {
      this[type] = false;
      this.isLoading = false;
      this.getDropArtworkDetail();
    },
    goTagSearch(tag) {
      clearInterval(this.polling);
      this.$router.push({
        name: 'search',
        params: { keyword: tag },
      });
    },
    async purchaseEvt() {
      if (this.artworkInfo.bcNwTp == '10' && !this.isMob) {
        this.showQrModal = true;
        return false;
      }

      if (!this.accessToken) {
        this.loginPopup();
        return false;
      }

      if (!(await this.checkZipcode()) && this.stateYn === 'N') {
        this.selectArtworkId = this.artworkInfo.artworkId;
        this.openModal('UpdateInfoModal');
        return false;
      }

      if (this.isLoading) return false;

      if (this.artworkInfo.bcNwTp == '20') {
        this.ethPurchase();
      } else {
        let resultTax = this.artworkInfo.tax ? this.artworkInfo.tax : 0;
        this.resultPrice = (
          parseFloat(this.artworkInfo.price) + parseFloat(resultTax)
        ).toFixed(2);

        // Blade
        if (this.walletOpt.value === 'WLT001') {
          if (this.hdrWallet.walletYn === 'N') {
            this.connectWallet();
          } else {
            this.openAppPurchase();
          }
          // Hedera
        } else {
          let insufficientBalance = false;
          let walletInfo = {
            walletType: this.walletOpt.value,
            resultPrice: 0,
          };

          if (this.hdrWallet.walletYn === 'N') {
            this.showWallet = true;
            return false;
          } else {
            await this.getWalletCheck();
            let resultUsdc = parseFloat(this.usdcBalance).toFixed(2);

            if (Number(this.resultPrice) >= Number(resultUsdc)) {
              insufficientBalance = true;
            }
          }
          if (this.walletTokenExpired)
            this.$root.$emit('walletTokenExpired', walletInfo);
          else if (this.walletChange)
            this.$root.$emit('walletChange', walletInfo);
          else if (this.associatedYn === 'N') {
            if (this.associatedError) {
              this.$root.$emit('openAlert', this.apiFailAlert);
            } else {
              this.showUsdc = true;
              this.usdcModalInfo = this.accountInfo;
            }
          } else if (insufficientBalance) {
            this.showUsdc = true;
            this.associatedSign = '';
            // NOTE: 220826 modal message change requested by pm
            this.usdcModalInfo = this.InsufficientModalInfo;
          } else this.openAppPurchase();
        }
      }
    },
    openModal(ref) {
      this.$refs[ref].open();
    },
    loginPopup() {
      this.$EventBus.$emit('empLogin');
    },
    openContactModal() {
      if (!this.accessToken) {
        this.$EventBus.$emit('empLogin');
      } else {
        this.$refs['ContactArtistModal'].open();
      }
    },
    async ethPurchase() {
      let purchaseParams = this.query;
      purchaseParams.bcNwTp = '20';

      //주소가 없으면 지갑 연결
      if (!this.getWeb3ModalStatus.address) {
        this.W3M.open();
        return false;
      }

      if (this.isLoading) return false;

      this.showEthProgress = true;
      this.isLoading = true;
      await this.api.getQrUrlForDropPurchase(purchaseParams).then(async res => {
        if (res.code === 0) {
          try {
            let mint = res.data;
            let transactionId = res.transactionId;

            let txResult = await this.W3F.sendTransaction({
              from: this.getWeb3ModalStatus.address,
              data: mint.data,
              to: mint.to,
              value: this.toHexEth(mint.value),
            });

            let hash = txResult;
            // this.getWeb3ModalStatus.providerType == 'walletConnect'
            //   ? txResult
            //   : txResult.hash;

            if (txResult) {
              let ethParams = {
                transactionId: transactionId,
                transactionHash: hash,
              };
              this.ethRegister(ethParams, transactionId);
            } else {
              this.showEthProgress = false;
              this.isLoading = false;
            }
          } catch (e) {
            this.showEthProgress = false;
            this.isLoading = false;

            let errMsg;
            let errorMsg;
            let haveVL = '';
            let wantVL = '';
            let isNotEnough = false;
            if (e.info.error.code == -32000) {
              errMsg = `You don't have enough ETH
              in your wallet for this transaction.`;
              try {
                const haveValue = e.info?.error?.message?.split('have ');
                const wantValue = e.info?.error?.message?.split('want ');
                wantVL = wantValue[1] / 1000000000000000000;
                haveVL = haveValue[1].split(' ')[0] / 1000000000000000000;
                isNotEnough = true;

                errorMsg = {
                  type: 'warn',
                  desc: errMsg,
                  titleDescription: isNotEnough ? 'Not enough ETH' : null,
                  additional: [
                    {
                      label: 'Remaining',
                      value: parseFloat(haveVL).toFixed(3) + ' ETH',
                    },
                    {
                      label: 'Required',
                      value: parseFloat(wantVL).toFixed(3) + ' ETH',
                    },
                  ],
                };
              } catch (er) {
                console.log(er);
              }
            } else {
              errMsg = e.info?.error?.message ?? e.message ?? 'MetaMask Error'; //eslint-disable-line no-unused-vars
              errorMsg = {
                type: 'warn',
                desc: errMsg,
              };
            }
            this.$root.$emit('openAlert', errorMsg);
          }
        } else {
          this.showEthProgress = false;
          this.isLoading = false;
          let errorMsg = {
            type: 'warn',
            desc: res.message,
          };
          this.$root.$emit('openAlert', errorMsg);
        }
      });
    },
    async ethRegister(params, transactionId) {
      await this.api.getDropEthRegister(params).then(res => {
        if (res) {
          this.polling = setInterval(() => {
            this.ethCallback(transactionId);
            console.log(this.overtimer);
            if (this.overtimer <= 0) {
              console.log('polling in clear');
              this.showEthProgress = false;
              this.isLoading = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          this.showEthProgress = false;
          this.isLoading = false;
          let errorMsg = {
            type: 'warn',
            desc: res.message,
          };
          this.$root.$emit('openAlert', errorMsg);
        }
      });
    },
    async ethCallback(transactionId) {
      console.log(transactionId);
      let callBackParams = {
        transactionId,
        dropId: this.dropId,
      };
      await this.api.getDropEthCallBack(callBackParams).then(res => {
        if (res.code == 0) {
          if (res.purchaseCmpltYn === 'Y') {
            clearInterval(this.polling);
            this.$router.push({
              name: 'purchase',
              params: {
                signAddr: res.contractAddr,
                tokenId: res.tokenId,
                purchase: 'eth',
              },
            });
          } else if (res.purchaseCmpltYn === 'N') {
            clearInterval(this.polling);
            this.$router.push({
              name: 'purchase',
              params: {
                signAddr: res.contractAddr,
                tokenId: res.tokenId,
                purchase: 'ethfail',
              },
            });
            this.showEthProgress = false;
            this.isLoading = false;
          }
        } else {
          clearInterval(this.polling);
          this.showEthProgress = false;
          this.isLoading = false;
          let errorMsg = {
            type: 'warn',
            desc: res.message,
          };
          this.$root.$emit('openAlert', errorMsg);
        }
      });
    },
    async openAppPurchase() {
      if (this.walletOpt.value === 'WLT001' && !this.openBlade) {
        this.$root.$emit('openAlert', this.appMessage('WLT001'));
        return false;
      }
      if (this.walletOpt.value === 'WLT002' && !this.openWallypto) {
        this.$root.$emit('openAlert', this.appMessage('WLT002'));
        return false;
      }

      this.isLoading = true;
      let purchaseParams = this.query;

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      purchaseParams.bcNwTp = '10';
      //}

      await this.api.getQrUrlForDropPurchase(purchaseParams).then(res => {
        this.isLoading = false;
        let walletInfo = {
          walletType: this.walletOpt.value,
          resultPrice: this.resultPrice,
          NFTImage: this.bladeAppImage,
          NFTName: this.artworkInfo.title,
        };

        if (res.code === 0) {
          if (this.walletOpt.value === 'WLT001') {
            if (!this.resultPrice) return false;

            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'purchase',
              price: this.resultPrice,
              purchaseType: 'drop',
              NFTImage: this.bladeAppImage,
              NFTName: this.artworkInfo.title,
            };
            if (this.dropId === 'D0000000011') {
              params.NFTName = 'JE_C1-2_01';
            }
            console.log(params);
            this.onBlade(params, this.email);
          } else {
            this.onCallApp(res.signUrl);
          }

          clearInterval(this.polling);
          this.$router.push({
            name: 'purchase',
            params: { signAddr: res.signAddr, purchase: 'drops' },
          });
        } else if (res.code === 1033) {
          this.$root.$emit('walletTokenExpired', walletInfo);
        } else if (res.code === 1034) {
          this.$root.$emit('walletChange', walletInfo);
        } else {
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    makingfilmPlayPause() {
      if (this.isPlay === false) {
        this.isPlay = true;
        this.$refs.video.play();
        this.playerTimeoutId = setTimeout(() => {
          this.isPlayer = false;
        }, 3000);
      } else {
        this.isPlay = false;
        this.$refs.video.pause();
        clearTimeout(this.playerTimeoutId);
      }
    },
    mousePlayOver() {
      this.isPlayer = true;
    },
    mousePlayleave() {
      this.isPlayer = false;
      clearTimeout(this.playerTimeoutId);
    },
    likeThis(id) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        this.$refs.icLike.setLiveDropsFavorites(id, this.likeStatus);
        this.likeStatus = !this.likeStatus;
      }
    },
    setTab(id) {
      this.currentTab = id;
      const topGap = this.isMob ? 60 : 0;
      window.scrollTo(0, this.tabPos - topGap);
      this.$refs.tabs.scrollIntoView();
    },
    setFixedTab() {
      const st = window.pageYOffset;
      const tabWrap = this.$refs.tabs;
      const topGap = window.innerWidth <= 1024 ? 60 : 0;
      if (tabWrap) {
        this.tabPos = tabWrap.getBoundingClientRect().top + st;
        if (this.tabPos - topGap < st) tabWrap.classList.add('is_fix');
        else tabWrap.classList.remove('is_fix');
      }
    },
    setDocentCurrent(item, idx) {
      this.currentDocent = { idx, item };
    },
    walletOtp(opt, idx) {
      this.walletSelect = false;
      if (opt.value === this.walletOpt.value) {
        return;
      }
      this.walletOpt = opt;
      this.walletFocusOpt = idx;
    },
    tabManager() {
      if (this.editionItems?.length) {
        let tab = this.tabQueue.find(tab => tab.id == 'editions');
        this.infoTabData.push(tab);
      }

      if (this.docentInfo?.items?.length) {
        let tab = this.tabQueue.find(tab => tab.id == 'docent');
        this.infoTabData.push(tab);
      }
      if (this.makingInfos?.length) {
        let tab = this.tabQueue.find(tab => tab.id == 'film');
        this.infoTabData.push(tab);
      }
    },

    async getDropArtworkDetail() {
      await this.api.getDropArtworkDetail(this.query).then(res => {
        if (res.code === 0) {
          this.artworkInfo = res.artworkInfo;
          this.artworkId = this.artworkInfo.artworkId;
          this.artistAvatarInfo = this.artworkInfo.artistAvatarInfo;
          this.docentInfo = this.artworkInfo.docentInfo;
          this.makingInfos = this.artworkInfo.makingInfos;
          this.imageInfos = this.artworkInfo.imageInfos;

          // this.source : 동영상이든 이미지이든 작품 원본 자체가 담기는 변수
          // this.source_medium : 동영상의 경우 0번째 배열 썸네일 / 이미지는 1번째 배열 이미지
          // this.source_small : 동영상의 경우 1번째 배열 썸네일 / 이미지는 2번째 배열 이미지 (width:598)
          // this.artworkBackground : 백그라운드 이미지

          if (this.artworkInfo.mimeType === 'video/mp4') {
            // NOTE: Origin Code
            // this.artworkBackground = this.artworkInfo.videoThumbInfos[0].resUrl;
            // this.source = this.source_medium =
            //   this.artworkInfo.videoInfos[1].resUrl;

            // NOTE: 23-02-15 expose FHD image ( rsrcTp 111 - 1920x1080 ) requested by hyLee (PM)
            let standardOfThumbnailPriority = 111;
            let _videoThumbInfos = this._sortByStandard(
              [...this.artworkInfo?.videoThumbInfos],
              standardOfThumbnailPriority,
            );
            this.artworkBackground = _videoThumbInfos[0]?.resUrl;

            // NOTE: 23-02-15 expose HD video ( rsrcTp 102 - 1280x720 ) requested by hyLee (PM)
            let standardOfVideoPriority = 102;
            let _videoInfos = this._sortByStandard(
              [...this.artworkInfo?.videoInfos],
              standardOfVideoPriority,
            );
            this.source = _videoInfos[0]?.resUrl;

            this.source_medium = this.artworkInfo.videoInfos[1].resUrl;
            this.source_small = this.artworkInfo.videoInfos[1].resUrl;
            this.bladeAppImage = this.artworkInfo.videoThumbInfos[1].resUrl;
          } else {
            this.artworkBackground = this.source =
              this.artworkInfo.imageInfos[0].resUrl;

            this.source_medium = this.artworkInfo.imageInfos[1]?.resUrl;
            this.source_small = this.artworkInfo.imageInfos[2]?.resUrl;

            this.bladeAppImage = this.artworkInfo.imageInfos[2]?.resUrl;
          }
          if (this.artworkInfo.likeYn === 'Y') {
            this.likeStatus = true;
          }
          this.docentInfo = this.artworkInfo.docentInfo;
          this.docentThumbItems = this.docentInfo.items;
          this.currentDocent.item = this.docentThumbItems[0];

          if (this.artworkInfo.dropsDivision == 'ended') {
            this.getDropStatus = 'Ended';
          } else if (this.artworkInfo.dropsDivision == 'upcoming') {
            this.getDropStatus = 'Upcoming';
          } else {
            if (this.artworkInfo.soldOutYn == 'Y') {
              this.getDropStatus = 'Sold Out';
            } else {
              if (this.artworkInfo.bcNwTp == '20') {
                if (this.ethWallet.walletYn == 'N') {
                  this.getDropStatus = 'Connect Wallet to Purchase';
                } else {
                  this.getDropStatus = 'Buy Now';
                }
              } else {
                if (this.isMob) {
                  if (!this.accessToken) {
                    this.getDropStatus = 'Sign In to Purchase';
                  } else if (this.hdrWallet.walletYn == 'N') {
                    this.getDropStatus = 'Connect Wallet to Purchase';
                  } else {
                    this.getDropStatus = 'Purchase(USDC Hedera Only)';
                  }
                } else {
                  this.getDropStatus = 'Purchase';
                }
              }
            }
          }
        } else {
          clearInterval(this.polling);
          this.$router.push('/drops');
        }
      });
    },
    async getNftBalance() {
      let walletParams = {};
      await this.api.getNftBalance(walletParams).then(res => {
        if (res.code === 0) {
          this.accountId = res.bc_account;
          this.usdcBalance = res.usdcBalance;
        }
      });
    },
    async getWalletCheck() {
      this.isLoading = true;

      await this.getNftBalance();

      if (!this.accountId) {
        this.associatedError = true;
        return false;
      }
      let walletParams = { accountId: this.accountId };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      await this.api.getUsdcAssociation(walletParams).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.associatedYn = res.associatedYn;
          this.associatedSign = res.signUrl;
          this.associatedError = false;
          this.walletTokenExpired = false;
          this.walletChange = false;
        } else if (res.code === 1033) {
          this.walletTokenExpired = true;
          this.walletChange = false;
        } else if (res.code === 1034) {
          this.walletTokenExpired = false;
          this.walletChange = true;
        } else {
          this.walletTokenExpired = false;
          this.walletChange = false;
          this.associatedError = true;
        }
      });
    },
    async connectWallet() {
      if (this.isLoading) return false;
      this.isLoading = true;
      clearInterval(this.polling);

      let walletParams = {
        wlltType: 'WLT001',
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      await this.api.connectWallet(walletParams).then(res => {
        if (res.code === 0) {
          let params = {
            hostServer: res.hostServer,
            signAddr: res.signAddr,
            scheme: 'setup',
            price: this.resultPrice,
            purchaseType: 'drop',
            NFTImage: this.bladeAppImage,
            NFTName: this.artworkInfo.title,
          };
          if (this.dropId === 'D0000000011') {
            params.NFTName = 'JE_C1-2_01';
          }
          console.log(params);
          this.onBlade(params, this.email);

          setTimeout(() => {
            this.isLoading = false;
          }, 10000);

          this.polling = setInterval(() => {
            this.getProfile();
            if (this.overtimer <= 0) {
              this.isLoading = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getProfile() {
      await this.api.getProfile({}).then(res => {
        if (res.code === 0) {
          let hdrWallet = res.walletInfos
            ? res.walletInfos.find(v => v.bcNwTp === '10')
            : {};
          if (hdrWallet.walletYn === 'Y') {
            this.isLoading = false;
            this.setHdrWallet(hdrWallet);
            clearInterval(this.polling);
            this.getNftBalance();
            this.getDropArtworkDetail();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    getRefresh() {
      this.polling = setInterval(() => {
        this.getStatusInfos();
      }, 120000);
    },
    async getStatusInfos() {
      let params = {
        dropId: this.dropId,
        artworkId: this.artworkId,
      };
      await this.api.getDropCurrentStatus(params).then(res => {
        if (res.code === 0) {
          this.statusInfos = res.statusInfos;
        }
      });
    },

    goToProfile(userType, mbrNo) {
      if (userType === '1') {
        this.$router.push(`/mylist/other/${mbrNo}`);
      }
      if (userType === '2') {
        this.$router.push(`/mylist/artist/${this.artworkInfo.artistId}`);
      }
    },

    loadMore() {
      // 에디션 전체정보에서 받아온 토탈 값 : this.artworkInfo.totalEdition
      if (this.viewCount < this.artworkInfo.totalEdition) {
        this.page++;
        this.viewCount += 20;
        this.query.page = this.page;
        this.api.getDropEditionItems(this.query).then(res => {
          if (res.code === 0) {
            // this.editionItems = [[], ...res.editionItems];
            this.editionItems.push(...res.editionItems);
          }
        });
      }
    },
    directUpdateInfo() {
      this.selectArtworkId = '';
      this.openModal('UpdateInfoModal');
    },
    async zipcodeSave(artworkId) {
      this.zipCodeValidation();
      await this.getDropArtworkDetail();

      if (!artworkId) return false;

      if (this.artworkInfo.bcNwTp == '10') {
        if (
          this.hdrWallet.walletYn === 'N' &&
          this.walletOpt.value === 'WLT002'
        ) {
          this.showWallet = true;
        } else {
          if (!this.$isMobile()) {
            this.showQrModal = true;
          } else {
            this.purchaseEvt();
          }
        }
      } else {
        this.ethPurchase();
      }
    },
    // TODO : resource List sort method commonization ( like mixins )
    _sortByStandard(resourceArray, standardOfPriority) {
      if (resourceArray && resourceArray.length > 0) {
        resourceArray.sort((a, b) => {
          if (
            Math.abs(a.rsrcTp - standardOfPriority) >
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return 1;
          } else if (
            Math.abs(a.rsrcTp - standardOfPriority) <
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return -1;
          } else {
            return a.rsrcTp < b.rsrcTp ? 1 : -1;
          }
        });
      }
      return resourceArray;
    },
    ...mapMutations({
      setHdrWallet: 'userStore/hdrWallet',
    }),
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.setFixedTab);
    this.isLoading = false;
    clearInterval(this.polling);
  },
};
</script>
<style scoped>
@import '../../assets/css/detail.css';
@import url('../../assets/css/customGlobal.css');

.thumb_item {
  position: relative;
  cursor: pointer;
}
.thumb_item .ic_area {
  position: absolute;
  z-index: 10;
}
/* .thumb_item .ic_area {
  width: 40px;
  height: 40px;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.thumb_item .ic_area.type_video {
  right: 7px;
  top: 7px;
} */
.thumb_item .ic_area.type_blockchains {
  width: 34px;
  height: 37px;
  padding: 0;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  box-sizing: border-box;
  border-radius: 0;
  right: 10px;
  top: 10px;
}
.isDeactive {
  background-color: #333;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .thumb_item .ic_area.type_blockchains {
    top: 7px;
    right: 7px;
    width: 2.8rem !important;
    height: 3rem !important;
  }

  .thumb_item .ic_area.type_blockchains i {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
